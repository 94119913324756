import React, { useState } from 'react';
import MiniCartItem from "./miniCartItem";
import { useShoppingCart } from 'use-shopping-cart';
import styled from "styled-components";

const CartContainer = styled.div`
  z-index:100;
  position:absolute;
  left:10px;
  top:-400px;
  background-color:white;
  width:70%;
  margin:auto;
  border:1px solid black;
  @media(max:500px){

  
  &.cart-show{
    top:333px;
  }
  &.cart-hidden{
      right:inherit;
  }
}
`;

const MiniCartItems = ({ items }) => {
  return Object.keys(items).map(key => {
    return <MiniCartItem item={items[key]} key={key} />;
  });
};

const Cart = (props) => {
  const { className } = props;
  const [loading, setLoading] = useState(false);
  /* Gets the totalPrice and a method for redirecting to stripe */
  const {
    formattedTotalPrice,
    redirectToCheckout,
    cartCount,
    clearCart,
    cartDetails
  } = useShoppingCart();

  const handleClearCart = () => {
    try {
      clearCart();
    } catch (e) {
      console.log('broke');
    }
  };

  const handleCheckout = (isLoading) => {
    setLoading(isLoading);
    redirectToCheckout();
  };

  const checkoutProps = {
    loading, handleClearCart, handleCheckout
  };

  return (
    <div className="row">
      {cartCount > 0 ?
        <div className="p-4 col-12 col-lg-6">

          <h2 className="mb-3">Cart Items</h2>
          <CheckoutButtons {...checkoutProps} />
          <p># Items: {cartCount}</p>
          <p>Total: {formattedTotalPrice}</p>
          <MiniCartItems items={cartDetails} />
          <CheckoutButtons {...checkoutProps} />

        </div>
        :
        <h5>You have nothing in your cart. The horror!</h5>
      }
    </div>
  );
};

const CheckoutButtons = ({ loading, handleClearCart, handleCheckout }) => (
  <div className="d-flex justify-content-center">
    <button className="checkout-button"
      disabled={loading}
      onClick={() => {
        handleCheckout(true);
      }}
    >
      {loading ? 'Loading...' : 'Checkout'}
    </button>
    <button className="checkout-button" id="clearCart" onClick={handleClearCart}>
      Clear cart
  </button>
  </div>
);
export default Cart;