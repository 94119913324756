import React from "react";
import styled from "styled-components";
import { formatCurrencyString } from "use-shopping-cart";

const MiniCartItemContainer = styled.div`

div{
  padding: 0;
  font-size: 11px;

  .item-name {
    font-weight: bold;
  }
}
`;

const MiniCartItem = ({ item }) => {
  return (
    <MiniCartItemContainer className="border-bottom p-1 w-100 mt-2">
      <div className="product-container">
        <div className="item-name">{item.name}</div>
        <div>Quantity: {item.quantity}</div>
        <div>Price per: {formatCurrencyString({
          value: parseInt(item.price, 10),
          currency: 'USD',
        })}</div>
        <div>Total: {formatCurrencyString({
          value: parseInt(item.value, 10),
          currency: 'USD',
        })}</div>
      </div>
      <img src={item.image[0]} className="d-none d-md-block float-left" />
    </MiniCartItemContainer>
  );
};

export default MiniCartItem;