import React, { useState } from "react"
import CartOverview from "../components/Cart/cartOverview";
import { useShoppingCart } from 'use-shopping-cart';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Products from '../components/Products/products'

const Cart = (props) => {

  const {
    cartCount
  } = useShoppingCart();

  return (<Layout location={props.location} title="Store Checkout">
    <SEO title="Home" keywords={[`jackalope adventures cart`]} />
    <CartOverview />
  </Layout >)
}

export default Cart